import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { Route } from "react-router-dom";
import { Layout, message, Dropdown, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchSession, setSession } from "store/session/actions";
import Fallback from "./Fallback";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MenuOutlined, LogoutOutlined } from '@ant-design/icons';
import { getThemeBackgroundURL } from "utils/utils";
import { logout } from "utils/auth";
import { fetchClientBasicData } from "store/clientBasicData/actions.js";
import { getUserLogoutRequest } from "../components/LayoutWrapper/requests"

import styles from "./styles.module.less";

const { Header } = Layout;

const PrivateRoute = ({ children, ...props }) => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const subdomain = _.get(props, "computedMatch.params.portalSlug");
  const dispatch = useDispatch();
  const history = useHistory();
  const session = useSelector((globalState) => globalState.session);
  const clientBasicData = useSelector((globalState) => globalState.clientBasicData);
  const isLoggedIn = !!_.get(session, "user.customer._id");

  const handleLogout = useCallback(async () => {
    setIsLoggingOut(true);
    await getUserLogoutRequest();
    logout();
    setIsLoggingOut(false);
    dispatch(setSession({}));
    history.push(`/${subdomain}/auth/login`);
  }, [dispatch, history, subdomain])

  useEffect(() => {
    dispatch(fetchSession(subdomain));
  }, [dispatch, subdomain]);

  useEffect(() => {
    dispatch(fetchClientBasicData(subdomain));
  }, [dispatch, subdomain]);

  useEffect(() => {
    if(session.loading || clientBasicData.loading) return;
    if(isLoggingOut) return;

    const customerOfPortalId = _.get(session, "user.customer.portalSlug");
    const portalSlug = _.get(clientBasicData,"portal.slug");

    if(customerOfPortalId !== portalSlug) {
      message.info('Logging out...')
      handleLogout();
    }

  }, [clientBasicData, handleLogout, isLoggingOut, session])

  if (session.loading || clientBasicData.loading) {
    return <Fallback active />;
  }

  if (!isLoggedIn) {
    history.push(`/${subdomain}/auth/login`);
  }

  // const firstName = _.get(session, "user.customer.firstName", "FrontBase");
  // const lastName = _.get(session, "user.customer.lastName", "Customer");

  const backgroundImageId = _.get(clientBasicData,"portal.theme.background");

  const items = [
    {
      key: "logout",
      label: <span onClick={handleLogout}><LogoutOutlined /> Logout </span>
    }
  ]

  return (
    <Route {...props}>
      <div className={styles.homeScreenContainer}>
        <Header className={styles.headerSection}>
          <div className={styles.logo} onClick={() => history.push(`/${subdomain}/home`)}>
            <img src={clientBasicData.portal.logo} alt="logo"/>
          </div>
          <Dropdown className={styles.dropDown} menu={{ items }} placement="bottomLeft">
            <Button>
              <MenuOutlined />
            </Button>
          </Dropdown>
          <Button className={styles.logoutButton} onClick={handleLogout}>
            <LogoutOutlined /> Logout
          </Button>
        </Header>
        {props.showBackgroundImage && <div className={styles.backgroundImage} style={{ backgroundImage: `url("${getThemeBackgroundURL(backgroundImageId)}")` }} /> }
        {React.cloneElement(children, props)}
      </div>
    </Route>
  );
};

export default PrivateRoute;
